<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="Time/Pressure Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Time/Pressure Test Description"
              size="medium"
            />
            <img src="@/assets/IPSeries/TimePressureDetails-min.png" />
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of the Time/Pressure test is to determine whether
              igniting a substance under confinement will result in a
              deflagration with explosive violence. Applicable to homogeneous
              substances.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              The Time/Pressure test is used in both Test 1 (c) and Test 2 (c).
              In UN Test 1 (c) the pressure is monitored to see if it reaches
              2070 kPa (300 psig) whereas in UN Test 2 (c) the pressure is
              monitored to see if it transitions from 690-2070 kPa (100-300
              psig) in less than 30 msec.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Confining medium (steel vessel)</td>
                      <td>Confined vessel protected with a burst disk</td>
                      <td></td>
                      <td>
                        Cylindrical steel pressure vessel 89mm in length and
                        60mm external diameter
                      </td>
                    </tr>
                    <tr>
                      <td>Sample size</td>
                      <td>
                        Provide sufficient mass to enable repeatable
                        determination of the test outcome
                      </td>
                      <td></td>
                      <td>5 grams</td>
                    </tr>
                    <tr>
                      <td>Number of trials</td>
                      <td>
                        Obtain an accurate characterization of the test sample
                      </td>
                      <td></td>
                      <td>3 trials</td>
                    </tr>
                    <tr>
                      <td>Ignition stimulus (igniter size and type)</td>
                      <td>
                        Ensure a sufficient amount of the material is ignited
                      </td>
                      <td></td>
                      <td>
                        Electric fusehead with a 13mm square piece of primed
                        cambric (linen fabric coated on both sides with a
                        potassium nitrate/ silicon/ sulphurless gunpowder
                        pyrotechnic composition) or equivalent
                      </td>
                    </tr>
                    <tr>
                      <td>Pressure transducer and recording system</td>
                      <td>
                        Measure the pressurization rate within the steel vessel
                      </td>
                      <td></td>
                      <td>
                        Capable of responding to rates of pressure rise 690 –
                        2070 kPa (100 – 300 psig) in not than more 5 msec and
                        not affected by hot gases or decomposition products
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Vessel pressure</td>
                    <td>Pressure transducer</td>
                    <td>
                      Pressure rise of 690-2070 kPa (100-300 psig) in &lt;30
                      msec: [Class 1]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <a
              :href="require('../../assets/IPSeries/setuptimep-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/setuptimep-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Rapid Deflagration (+) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/gotpressure-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/gotpressure-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Rapid Deflagration (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130070?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130070"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Slow Deflagration (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130071?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130071"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
